
@import url('https://fonts.googleapis.com/css?family=Raleway:400,500');
@font-face {
  font-family: 'Hafs';
  src: local('Hafs'), url(./Hafs.ttf) format('ttf');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}
@font-face {
  font-family: 'Al Mushaf Quran';
  src: local('Al Mushaf Quran'), url(./Al Mushaf Quran.ttf) format('ttf');
}

Al Mushaf Quran
* {
  margin: 0;
  padding: 0;
  list-style: none;
  vertical-align: baseline;
}

div {
  position: relative;
  z-index: 2;
}

body {
  background-color: #333;
  color: #333;
  font-family: 'Al Mushaf Quran','Hafs', 'Raleway', sans-serif;
  font-weight: 400;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.footer {
  max-width: 450px;
  text-align: center;
  display: block;
  margin: 15px auto 30px auto;
  font-size: 0.8em;
  color: #fff;
}
.footer a {
  font-weight: 500;
  text-decoration: none;
  color: #fff;
}

#quote-box {
  border-radius: 3px;
  position: relative;
  max-width: 450px;
  padding: 40px 50px;
  display: table;
  background-color: #fff;
}
#quote-box .aya-text {
  text-align: center;
  max-width: 450px;
  height: auto;
  clear: both;
  font-weight: 500;
  font-size: 1.75em;
}
#quote-box .aya-text i {
  font-size: 1em;
  margin-right: 0.4em;
}
#quote-box .aya-sorah {
  max-width: 450px;
  height: auto;
  clear: both;
  padding-top: 20px;
  font-size: 1em;
  text-align: right;
}
#quote-box .buttons {
  max-width: 450px;
  margin: auto;
  display: block;
}
#quote-box .buttons .button {
  height: 38px;
  border: none;
  border-radius: 3px;
  color: #fff;
  background-color: #333;
  outline: none;
  font-size: 0.85em;
  padding: 8px 18px 6px 18px;
  margin-top: 30px;
  opacity: 1;
  cursor: pointer;
}
#quote-box .buttons .button:hover {
  opacity: 0.9;
}

#quote-box .buttons .button.sb-share{
  float: left;
  padding: 0px;
  padding-top: 8px;
  text-align: center;
  font-size: 1.2em;
  margin-right: 5px;
  height: 30px;
  width: 40px;
}
#quote-box .buttons .button#new-quote {
  float: right;
}
